/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';
/*
NavBar
================================================================================
*/

.navbar {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;
  height: 60px;

  /*
  Layout
  ------------------------------------------------------------
  */
  .navbar__row {
    height: 100%;
  }

  .navbar__col {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      text-align: center;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &:not(:first-child):not(:last-child) {
      text-align: center;
    }

    &:last-child {
      text-align: right;
    }
  }

  /*
  Brand
  ------------------------------------------------------------
  */
  .navbar__brand {
    display: block;
    height: 100%;

    text-align: center;
  }

  .navbar__brand__link {
    height: 100%;
    text-align: right;
  }

  .navbar__brand__img {
    position: relative;
    /* width: 120px; */
    max-width: 120px;
    max-height: 46px;
    /* padding-top: 18px; */
  }

  /* :global(.navbar-right li) {
    float: right;
  } */

  .location-logo {
    display: inline-block;
    float:right;
    padding-left: 0.5rem;
  }

  /* .location-dropdown {
    display: inline-block;
    padding-top: 13px;

    select {
      font-size: 17px;
      font-weight: 700;
    }
  } */

.location-dropdown {
  float: right;
  margin-top: 0.5rem;
}

.location {
    height: 100%;
  }

  .mini_logo{
    height:40px;
    padding-right:10px;
    margin-top: 10px;
  }

  :global(.dropdown-menu>li>a) {
    padding: 3px 0;

    &:hover {
      background-color: inherit;
    }
  }

.location-logo-bigger {
  width: 100px;
  height: 100px;
  display: inline-block;

  img {
    width: 80px;
  }
}

.location-selector {
  text-align: right;
  padding-right: 1em;
}

.location-welcome {
  font-size: 13px;
  /* margin-right: 14px; */
  margin-bottom: -3px;
}

.topdropdown ul {
  width: 300px;
  border-top: none;
  border-radius: 0;
  margin-top: 15px;
  padding-top: 0;
  text-align: center;
}

  /*
  Text & Links
  ------------------------------------------------------------
  */
  .navbar__text {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: $color-primary-lowlight;

    font-weight: 600;
    text-transform: uppercase;
  }

  a.navbar__text {
    &:hover,
    &:active,
    &:focus {
      color: $color-secondary-lowlight;
      text-decoration: none;
    }
  }
}

.header-link {
  padding: 0 1rem;
  a {
    display: block;
  }

  a:focus,
  a:hover {
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
  }
  &.locations {
    border-top: 1px solid #ddd;
    padding: 0 0 1em;
    text-align: left;
  }
  &.top-link {
      display: inline-block;
    &:hover {
      background-color: #f6f6f6;
    }

    svg {
      margin-right: 0.3em;
    }
  }
}


.header-link--current {
  font-weight: bold;
}

.rightbar{
  padding-top:35px;
}

.null_navbar{
  height:0;
  min-height:0;
}

.navbar-dropdown {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  background: #282828; }
  .navbar-dropdown .navbar-logo {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out;
    vertical-align: middle; }
    .navbar-dropdown .navbar-logo img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
    .navbar-dropdown .navbar-logo.mbr-iconfont {
      font-size: 3.125rem;
      line-height: 3.125rem; }
  .navbar-dropdown .navbar-caption {
    font-weight: 700;
    white-space: normal;
    vertical-align: -4px;
    line-height: 3.125rem !important; }
    .navbar-dropdown .navbar-caption, .navbar-dropdown .navbar-caption:hover {
      color: inherit;
      text-decoration: none; }
  .navbar-dropdown .mbr-iconfont + .navbar-caption {
    vertical-align: -1px; }
  .navbar-dropdown.navbar-fixed-top {
    position: fixed; }
  .navbar-dropdown .navbar-brand span {
    vertical-align: -4px; }
  .navbar-dropdown.bg-color.transparent {
    background: none; }
  .navbar-dropdown.navbar-short .navbar-brand {
    padding: 0.625rem 0; }
    .navbar-dropdown.navbar-short .navbar-brand span {
      vertical-align: -1px; }
  .navbar-dropdown.navbar-short .navbar-caption {
    line-height: 2.375rem !important;
    vertical-align: -2px; }
  .navbar-dropdown.navbar-short .navbar-logo {
    margin-right: 0.5rem; }
    .navbar-dropdown.navbar-short .navbar-logo img {
      height: 2.375rem; }
    .navbar-dropdown.navbar-short .navbar-logo.mbr-iconfont {
      font-size: 2.375rem;
      line-height: 2.375rem; }
  .navbar-dropdown.navbar-short .mbr-table-cell {
    height: 3.625rem; }
  .navbar-dropdown .navbar-close {
    left: 0.6875rem;
    position: fixed;
    top: 0.75rem;
    z-index: 1000; }
  .navbar-dropdown .hamburger-icon {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    -webkit-box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
    -moz-box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
    box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828; }

.dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.dropdown-menu .dropdown-item:focus {
  outline: 0; }

.nav-dropdown {
  font-size: 0.75rem;
  font-weight: 500;
  height: auto !important; }
  .nav-dropdown .nav-btn {
    padding-left: 1rem; }
  .nav-dropdown .link {
    margin: .667em 1.667em;
    font-weight: 500;
    padding: 0;
    transition: color .2s ease-in-out; }
    .nav-dropdown .link.dropdown-toggle {
      margin-right: 2.583em; }
      .nav-dropdown .link.dropdown-toggle::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
        margin: 0;
        padding: 0.667em 3.263em  0.667em 1.667em; }
  .nav-dropdown .link::after,
  .nav-dropdown .dropdown-item::after {
    color: inherit; }
  .nav-dropdown .btn {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .nav-dropdown .dropdown-menu {
    border-radius: 0;
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: relative; }
  .nav-dropdown .dropdown-submenu {
    margin-left: 0.125rem;
    top: 0; }
  .nav-dropdown .dropdown-item {
    font-weight: 500;
    line-height: 2;
    padding: 0.3846em 4.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .nav-dropdown .dropdown-item::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .nav-dropdown .dropdown-item:focus, .nav-dropdown .dropdown-item:hover {
      background: none; }

@media (max-width: 767px) {
  .nav-dropdown.navbar-toggleable-sm {
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    width: 18.75rem;
    z-index: 999; } }
.nav-dropdown.navbar-toggleable-xl {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 18.75rem;
  z-index: 999; }

.nav-dropdown-sm {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .nav-dropdown-sm::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .nav-dropdown-sm.collapse.in ~ .navbar-close {
    display: block !important; }
  .nav-dropdown-sm.collapsing, .nav-dropdown-sm.collapse.in {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    background: #282828; }
  .nav-dropdown-sm.collapsing[aria-expanded="false"] {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%); }
  .nav-dropdown-sm .nav-item {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .nav-dropdown-sm .link,
  .nav-dropdown-sm .dropdown-item {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .nav-dropdown-sm .link:focus, .nav-dropdown-sm .link:hover,
    .nav-dropdown-sm .dropdown-item:focus,
    .nav-dropdown-sm .dropdown-item:hover {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #c0a375; }
  .nav-dropdown-sm .nav-btn {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .nav-dropdown-sm .nav-btn::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .nav-dropdown-sm .nav-btn + .nav-btn {
      padding-top: 0.625rem; }
      .nav-dropdown-sm .nav-btn + .nav-btn::before {
        display: none; }
  .nav-dropdown-sm .btn {
    padding: 0.625rem 0; }
  .nav-dropdown-sm .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .nav-dropdown-sm .dropdown-toggle[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .nav-dropdown-sm .dropdown-menu {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }
  .nav-dropdown-sm .dropdown-submenu {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: -1.25rem;
    top: 0; }

.navbar-toggleable-sm .nav-dropdown .dropdown-menu {
  position: absolute; }

.navbar-toggleable-sm .nav-dropdown .dropdown-submenu {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: -1.25rem;
  top: 0; }

.navbar-toggleable-sm.opened .nav-dropdown .dropdown-menu {
  position: relative; }

.navbar-toggleable-sm.opened .nav-dropdown .dropdown-submenu {
  left: 0;
  margin-left: 00rem;
  margin-top: 0rem;
  top: 0; }

.is-builder .nav-dropdown.collapsing {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */


:global(.navbar-default .container){
  height:60px;
  max-width: 1800px;
  margin-left: 0;
}

:global(.form-group){
  margin-bottom:none !important;
}

:global(.navbar-nav .form-control){
  background-color:white;
  color: #aaa;
  height: 100%;
  padding-left: 20px;
}

:global(.navbar-nav .form-control::-webkit-input-placeholder),
:global(.navbar-nav .form-control::-moz-placeholder),
:global(.navbar-nav .form-control::-ms-input-placeholder){
  color: #aaa;
}

:global(.navbar-nav .input-group){
  height: 100%;
}

:global(.navbar-nav .input-group .form-control){
  border:none;
}


:global(.navbar-nav .input-group .input-group-btn .btn){
  border:none;
}

.search-nav{
  color:#979797;
  border-left:1px solid #bbb;
  border-right:1px solid #bbb;
  height: 100%;
}

.likeGmail{
  min-height:100px;
  max-height:300px;
  overflow: auto;

  .pulldown-location {
    height: 2rem;
    line-height: 2rem;
    border-bottom: 1px solid #eee;
    padding: 0 1rem;

    &:hover {
      background-color: #f6f6f6;
    }

    &.master-dashboard {}
  }
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background:#777;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #777;
}

.topdropdown{
  white-space: pre-wrap;
}
.topdropdownsecond{
  white-space: pre-wrap;
  font-size:13px;
  font-weight:bold;
  top:-4px;
}
.topdropdownsecond a{
  color:white;
}

:global(.navbar-default .navbar-nav>li>a){
  padding-top:8px;
}

.navbar.withbanner {
  top: 50px;
}

.url{
  text-align:left;
  &:hover {
    background-color: #e8e8e8;
  }
}

.url-separator{
  color:#d7d7d7;
}

.pixel {
  width: 1px;
  height: 1px;
  background: gray;
}


 :global(.Toastify__toast--info svg) {
    color: #3498db;
 }
 :global(.Toastify__toast--success svg) {
  color: #07bc0c;
 }
 :global(.Toastify__toast--warning svg) {
  color: #f1c40f;
 }
 :global(.Toastify__toast--error svg) {
  color: #e74c3c;
 }