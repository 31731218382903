/*
Invite Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';


/*
Page Wide
================================================================================
*/



.smaller{
  font-size:.8rem;
}
/*
Invite Form Modal
================================================================================
*/

/* TODO */

@import '../../assets/styles/dentalhq_custom';