/*
Contact Us Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';
@import '../../assets/styles/dentalhq_custom';

/*
Page Wide
================================================================================
*/

.email{
  color:red;
  font-weight:bold;
  font-size:1.2rem;
}

.hidden_stuff{
  :global(.control-label){
    display:none;
  }
}