:global(.wrapper){
    margin-top:0 !important;
}

:global(.app-containers-App---wrapper){
  margin-top:0 !important;
}
:global(.container){
    width:100%;
    padding:0;
}

.container-wrapper {
    min-height: 100%;
  }

  .content-wrapper {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .user-name {
    font-weight: bold;
  }

/*
Page Header
================================================================================
*/
.page-header {
  color: $color-white;
  background: url(https://dentalhq-files.s3.amazonaws.com/marketing_materials/dentist-and-woman.jpg) center / cover no-repeat;

  text-align: center;

  .page-header__overlay {
    display: block;
    margin: 0;
    padding: 5rem 0;

    background-color: $color-primary-transparent;
  }

  .large-title {
    margin: 0;

    font-size: 3rem;
    text-transform: capitalize;

    &:not(:only-child) {
      margin-bottom: 3rem;
    }
  }

  form {
    br {
      display: none;
    }

    input[type=text] {
      margin-right: 10px;
    }

    input[type=submit] {
      background-color: $color-secondary-highlight;
      border-color: $color-secondary-highlight;

      &:hover {
        color: $color-secondary-highlight;
        background-color: $color-white;
      }
    }
  }
}

.border-content {
  min-height: 3.5rem; /* for pages without any border content */
  padding: 1rem 0;

  color: $color-white;
  background-color: $color-primary-lowlight;

  font-weight: lighter;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;

  a {
    color: #FFF;

    &:focus {
      color: #FFF;
      text-decoration: none;
    }

    &:hover {
      color: #FFF;
      text-decoration: underline;
    }
  }
}

.border-content--user {
  text-transform: capitalize;
}

.user-name {
  font-weight: bold;
}

.header-link {
  padding: 0 1rem;

  a:focus,
  a:hover {
    font-weight: bold;
    text-decoration: none;
  }
}

.header-link--current {
  font-weight: bold;
}

.small-welcome{
  font-size:.9rem;
}

.small-welcome-grey{
  font-size:.9rem;
  color:#a7a7a7;
  text-align:center;
  width:40%;
  font-weight:bold;
  margin:0 auto;
}


.other-offices{
  background-color: white;
    color: #272727;
    border-radius: 4px;
    font-size:.9rem;
    float:right;
}

/*
NavBar
================================================================================
*/
.navbar {
  box-shadow: -5px 1px 10px #888;
  z-index: 900 !important;

  /*
  Layout
  ------------------------------------------------------------
  */
  .navbar__row {
    height: 100%;
  }

  .navbar__col {
    height: 100%;

    &:first-child {
      text-align: left;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &:not(:first-child):not(:last-child) {
      text-align: center;
    }

    &:last-child {
      text-align: right;
    }
  }



  /*
  Brand
  ------------------------------------------------------------
  */
  .navbar__brand {
    display: block;
    height: 100%;

    text-align: center;
  }



  /*
  Text & Links
  ------------------------------------------------------------
  */
  .navbar__text {
    display: inline;
    position: relative;
    top: calc(50% - 1rem);

    color: $color-primary-lowlight;

    font-weight: 600;
    text-transform: uppercase;
  }

  a.navbar__text {
    &:hover,
    &:active,
    &:focus {
      color: $color-secondary-lowlight;
      text-decoration: none;
    }
  }
}

.header-link {
  padding: 0 1rem;

  a:focus,
  a:hover {
    font-weight: bold;
    text-decoration: none;
  }
}

.header-link--current {
  font-weight: bold;
}

.rightbar{
  padding-top:35px;
}

.null_navbar{
  height:0;
  min-height:0;
}

.navbar-fixed-top {
  position: fixed;
  background: #eee;
  max-width: 1800px;
}

.dentist-dashboard-header {
  font-family: 'Barlow Semi Condensed',sans-serif;
  padding:20px;
  min-height:300px;
}

.dash-box {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
