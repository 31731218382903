/* stylelint-disable */
:global {
  .spinner-icon {
    font-size: 18px;
    margin: 4px;

    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%; /* IE 9 */

    -webkit-animation: spinw .7s infinite linear;
       -moz-animation: spinm .7s infinite linear;
        -ms-animation: spin .7s infinite linear;

    -animation: spin .7s infinite linear;
  }

  .spinner-text {
    font-size: 14px;
  }
}

@keyframes :global(spinw) {
  from {
    transform: scale(1) rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes :global(spinm) {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
