:global(.clearfix::before),
:global(.clearfix::after) {
  content: ' ';
  display: table;
}

:global(.clearfix::after) {
  clear: both;
}

:global(.clearfix) {
  *zoom: 1;
}


/* elevio tab overrides */
:global(#_elev_io ._bzf50) {
  padding: 10px 25px !important;
}

:global(#_elev_io ._19znh ._bhq21) {
  transition: 0.3s ease;
  &:hover {
    background-color: #0181b2 !important;
  }
}
