/*
sidemenu
================================================================================
*/
.content_div_inner .accordion{
    /* background-color: #253746 !important; */
    margin-left: 1px;
    height: 100vh;
}
.content_div_inner .accordion .accordion__item{
    color:#fff;
    font-size:17px;
    font-weight:normal;
    background-color: inherit;
}

.styles_on_dashboard__drw3P {
    color: #fff !important;
    background-color: #00a1e1 !important;
    border-radius: 10px;
    width: 100%;
}
.styles_sidebar_admin__3KO53 .accordion__title{
    color: #fff !important;
    background-color: inherit !important;
    margin:0px 0;
}
.styles_sidebar_admin__3KO53 .accordion{
    padding:0px 0;
}
.styles_sidebar_admin__3KO53 .accordion__item + .accordion__item{
    border-top:1px solid #fff;
}
.styles_sidebar_admin__2o7Bg .accordion__title {
    color: #fff !important;
    background-color: transparent !important;
    cursor: pointer;
    padding: 15px 10px !important;
    width: 100%;
    text-align: left;
    border: none;
  }
  .styles_sidebar_admin__2o7Bg .accordion__item + .accordion__item {
    border-top: 1px solid #fff !important;
    margin: 0 15px;
  }
  .styles_on_dashboard__2b27u {
    color: #fff !important;
    background-color: #00a1e1 !important;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    border-radius: 10px !important;
    margin: 15px 0 !important;
  }


  /* this form here start sms setup page styles */


/* till here */