/*
Account Security Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';
@import '../../assets/styles/dentalhq_custom';

/*
Page Wide
================================================================================
*/


:global(.modal-sm) {
  @media (min-width: 768px) {
    width: 400px;
  }
}

#paymentplans #header {
  padding-top: 20px;
}

#paymentplans .button-create{
  padding: 20px 0;
}
.intro{}

.buttons {}

.explanation {
  margin-top: 1rem;
}

.explanation  {
    color: #767676;
    background: #fff;
}

.explanation ol {
    margin-bottom: 0;
    counter-reset: myCounter;
}

.explanation ol li {
    list-style: none;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

.explanation ol li:before {
    position: absolute;
    left: 0px;
    margin-top: -10px;
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    transition: all .2s;
    color: #ffffff;
    background: #149dcc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.stats-button{
  margin:1rem 0;
}
.metrics {
  color: $color-primary-lowlight;
}

.metrics__value {
  color: $color-primary-highlight;

  font-size: 1.125rem;
  font-weight: bold;
}

.metrics__entry {
  margin-bottom: 0.5rem;
}

.timeleft {
  display: inline-block;

  div {
    display: inline-block;
  }
}


