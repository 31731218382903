
:global(.custom-button){
    color:orange
}

.large-button--primary {
  border: 2px solid $color-primary-highlight;
  border-radius: 1rem;
  padding: 1.1rem 8.0rem;

  color: $color-white;
  background-color: $color-primary-highlight;

  font-size: 1.1rem;
  font-weight: 600;
  line-height: normal;

  &:hover {
    color: $color-primary-highlight;
    background-color: $color-white;
  }

  &:focus {
    outline: none;
  }
}

.large-button--secondary {
  border: 1px solid $color-secondary-highlight;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;

  color: $color-white;
  background-color: $color-secondary-highlight;

  font-weight: 600;
  line-height: normal;

  &:hover {
    color: $color-secondary-highlight;
    background-color: $color-white;
  }

  &:focus {
    outline: none;
  }

  &:disabled{
    background-color:$color-white;
    color: lightgrey;
    border: 1px solid lightgrey;
  }
}

.jumbotron {
    padding: 3rem 1rem 1rem;
    margin-bottom: 0;
    background-color: #00a1df;
    color: #fff;
    text-align: center;
    border-radius: 0;
    position: relative;
}

.dark{
    background-color: #00a1df;
    color: #fff;
}

.flex-container{
  display: flex;
}

.flex-container div{
  flex-grow:1;
  margin: auto;
}

.flex-center{
  justify-content:center;
  text-align:center;
}

.flex-cell3{
  width: calc(100% / 3);
}

.flex-cell4{
  width: calc(100% / 4);
}
.flex-cell8{
  width: calc(100% / 8);
}

.field-instructions {
  margin-bottom: 1.5rem;
  text-align: justify;
  font-weight: 300;

  &:last-child {
    margin-bottom: 2.5rem;
  }
}

.light-gray{
  color:#979797;
}

.spacer {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.spacer--after-form-group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.spacer--members-list {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.hidden-field {
  display: none;
}

.hidden-field + .align-with-input {
  padding-top: 0;
}

.white{
  color:#fafafa;
  &:hover {
    color: #cacaca;
  }
}

.white-background{
  background:#fff;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.modal-section-title {
  margin: 0 0 1rem 0;
  color: $color-primary-highlight;
  font-size: 1.5rem;
}

:global(.modal-body) {
  padding-bottom: 1.5rem;
}

.dhqblue{
  color:#00a1df;
}

.acceptsSMScheckbox input{
  opacity:.4;
  height: 0.8rem;
}

.acceptsSMS{
  font-size:.8rem;
  color: #999;
}