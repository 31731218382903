/*
Invite Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';
@import '../../assets/styles/dentalhq_custom';

/*
Page Wide
================================================================================
*/


/*
Invite Form Modal
================================================================================
*/

/* TODO */

.spanishcheckbox{
  padding: 15px;
  width:40%;
  float: left;
}

.languagepad{
  padding-top:11px
}


.spanishbox{
  padding: 0 15px 0;

  .language-radio {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -4px;

    &>div {
      padding-left: 0;
      font-size: 0.9rem;
    }

    input {
      width: 12px;
      margin-right: 5px;
    }
  }
}
