/*
Page Header Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';


/*
Page Header
================================================================================
*/


.page-header {
  color: $color-white;
  /* background: url(https://dentalhq-files.s3.amazonaws.com/marketing_materials/dentist-and-woman.jpg) center / cover no-repeat; */
  background-color: #00a1df;

  text-align: center;

  .page-header__overlay {
    display: block;
    margin: 0;
    padding: 2rem 0;

    background-color: $color-primary-transparent;

  }

  .large-title {
    margin: 0;

    font-size: 3rem;
    text-transform: capitalize;

    &:not(:only-child) {
      margin-bottom: 3rem;
    }
  }

  form {
    br {
      display: none;
    }

    input[type=text] {
      margin-right: 10px;
    }

    input[type=submit] {
      background-color: $color-secondary-highlight;
      border-color: $color-secondary-highlight;

      &:hover {
        color: $color-secondary-highlight;
        background-color: $color-white;
      }
    }
  }
}

.border-content {
  min-height: 3.5rem; /* for pages without any border content */
  padding: 1rem 0;

  color: $color-white;
  background-color: $color-primary-lowlight;

  font-weight: lighter;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;

  a {
    color: #FFF;

    &:focus {
      color: #FFF;
      text-decoration: none;
    }

    &:hover {
      color: #FFF;
      text-decoration: underline;
    }
  }
}

.border-content--user {
  text-transform: capitalize;
}

.user-name {
  font-weight: bold;
}

.header-link {
  padding: 0 1rem;

  a:focus,
  a:hover {
    font-weight: bold;
    text-decoration: none;
  }
}

.header-link--current {
  font-weight: bold;
}

.small-welcome{
  font-size:.9rem;
}

.small-welcome-grey{
  font-size:.9rem;
  color:#a7a7a7;
  text-align:center;
  width:40%;
  font-weight:bold;
  margin:0 auto;
}


.other-offices{
  background-color: white;
    color: #272727;
    border-radius: 4px;
    font-size:.9rem;
}
