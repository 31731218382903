/*
Account Security Form Modal Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';
@import '../../assets/styles/dentalhq_custom';

/*
Page Wide
================================================================================
*/


#paymentplans #header {
  padding-top: 20px;
}

#paymentplans .button-create{
  padding: 20px 0;
}
.intro{}

.buttons {}

.explanation {
  margin-top: 1rem;
}

.explanation  {
    color: #767676;
    background: #fff;
}

.explanation ol {
    margin-bottom: 0;
    counter-reset: myCounter;
}

.explanation ol li {
    list-style: none;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

.explanation ol li:before {
    position: absolute;
    left: 0px;
    margin-top: -10px;
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    transition: all .2s;
    color: #ffffff;
    background: rgb(0, 48, 87);
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.explanation ul {
  margin-bottom: 0;
  counter-reset: myCounter;
}

.explanation ul li {
  list-style: none;
  padding-left: .5rem;
  margin-bottom: 1rem;
}

.explanation ul li:before {
  position: absolute;
  left: 0px;
  margin-top: -10px;
  counter-increment: myCounter;
  content: counter(myCounter);
  display: inline-block;
  text-align: center;
  margin: 5px 10px;
  line-height: 40px;
  transition: all .2s;
  color: #ffffff;
  background: rgb(0, 48, 87);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.varidi-button{
  margin:1rem 0;
}

.highlightText {
  color: rgb(0, 48, 87);
}

.titleCheckboxes{
    color: rgb(0, 48, 87);
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 16px;
}

.subtitle{
    color: rgb(0, 48, 87);
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.text {
  color: rgb(56, 56, 56);
  font-size: .95rem;
}