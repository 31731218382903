/** Bootstrap overrides **/
:global(a) {
  color: $link-color;
}

:global(.link) {
  color: $link-color;
}

:global(.popover--large) {
  max-width: 360px;
  width: 360px;
}

:global(.popover-title) {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

:global(.popover-content) {
  text-align: justify;
}

:global(.navbar-default) {
  background-color: $white;
  border: none;
  margin-bottom: 0;
}

:global(.navbar-default .container) {
  height: 60px;
  width: 100%;
}

:global(.navbar-brand) {
  position: relative;
  height: 100%;
}

:global(.navbar-header) {
  height: 100%;
}

:global(.navbar-collapse) {
  padding-top: 2rem;
}

:global(button) {
  height: 2rem;
  font-size: 0.875rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  -webkit-touch-callout: none;
  user-select: none;
  border: none;
  outline: 0 !important;
  cursor: pointer;
  transition: background-color 200ms ease-out, box-shadow 100ms ease-out;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 2rem;
  padding-right: 2rem;
  &:active { padding-bottom: 0.2rem; }
}

:global(.btn-primary) {
  border-radius: 0;
}

:global(.btn-padding) {
  padding-left: 2rem;
  padding-right: 2rem;
}

:global(.btn-round) {
  border-radius: 1rem;
}

:global(.btn-green) {
  @mixin colored-button $white, $light-green, $green;
}

:global(.btn-darkest-green) {
  @mixin colored-button $white, $darkest-green, $white;
}

:global(.btn-cyan) {
  @mixin colored-button $white, $light-cyan, $cyan;
}

:global(.btn-outline) {
  border: 0.1rem solid $white;
}

:global(.btn-shadow) {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border: none;
}

/** forms **/
:global(.form-horizontal .control-label) {
  font-size: 1rem;
  font-weight: bold;
  padding: 0 0 0.5rem 0;
}

:global(.form-group) {
  /* margin-bottom: 1.5rem; */
}

:global(.form-group .col-sm-4) {
  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }
}

:global(select) {
-webkit-border-radius: 0;
border: 0;
outline: 1px solid rgba(189, 187, 187, 0.3);
outline-offset: -1px;
}

:global(.form-control) {
  /* height: 40px; */
  border: 1px solid rgba(189, 187, 187, 0.3); /* $color-secondary-lowlight in rgba form */
  border-radius: 0;

  color: #646569; /* $color-primary-lowlight */
  background-color: #f8f8f8;
  box-shadow: none;

  font-size: 1rem;

  &::-webkit-input-placeholder {
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }

  &:-moz-placeholder { /* Firefox 18- */
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }

  &:-ms-input-placeholder {
    opacity: 1;
    color: #646569; /* $color-primary-lowlight */

    font-size: 0.75rem;
  }

  &[type=checkbox]:focus,
  &[type=radio]:focus {
    box-shadow: none;
    outline: none;
  }
}

:global(input[type=checkbox]) {
  @media (max-width: 767px) {
    margin-left: 1px;
  }
}

:global(input[type=checkbox]),
:global(input[type=radio]) {
  cursor: pointer;

  &:focus,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

:global(.has-error .form-control) {
  border: 1px solid #a94442; /* add the border back for errors */

  box-shadow: none;
}

:global(.input-group-addon) {
  height: 34px;
  border: 1px solid rgba(189, 187, 187, 0.3); /* $color-secondary-lowlight in rgba form */
  border-radius: 0;

  color: #646569; /* $color-primary-lowlight */
  box-shadow: none;

  font-size: 1rem;
}

:global(.input-group-btn > .btn) {
  height: 2.5rem;
  border: 1px solid rgba(189, 187, 187, 0.5); /* $color-secondary-lowlight in rgba form */
  border-radius: 0;

  color: #646569; /* $color-primary-lowlight */
  box-shadow: none;

  font-size: 1rem;
}

:global(.form-control--large) {
  /* a 20% increase */
  height: 3rem;
  font-size: 1.2rem;

  &::-webkit-input-placeholder {
    font-size: 0.9rem;
  }

  &:-moz-placeholder { /* Firefox 18- */
    font-size: 0.9rem;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.9rem;
  }

  &:-ms-input-placeholder {
    font-size: 0.9rem;
  }
}

:global(.form-control--large + .input-group-addon),
:global(.form-control--large + .input-group-btn > .btn) {
  /* a 20% increase */
  height: 3rem;
  width: 3.15rem;
  font-size: 1.2rem;
}

:global(.help-block) {
  margin-bottom: 0;
  font-size: 0.8rem;
}

/*
Custom Redux-Form Components
------------------------------------------------------------
NOTE: Don't use CSS-Modules to wrap any custom redux-form components (passed
      to a redux-form field).  There are issues with redux-form interacting
      poorly with other high-level-components, including cases where React
      will interpret the wrapped component as a new component when the render
      method is called for an unrelated part of the page.  This will cause a lot
      of extra rendering to happen, and the user's current field element will
      be de-selected if it is the wrapped component.

      See https://github.com/erikras/redux-form/issues/1094
*/
:global {
  .input-checkbox {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.375rem;
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
    margin-left: 1px;
  }

  .input-checkbox-label {
    margin-bottom: 0;

    font-weight: 300;

    span {
      vertical-align: middle;
      margin-left: 0.25rem;
    }

    a {
      color: $color-primary-transparent;

      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: $color-primary-highlight;
        text-decoration: none;
      }
    }
  }

  .input-time {
    .input-time__time {
      display: inline-block;
      width: calc(100% - 62px);

      vertical-align: top;
    }

    .input-time__am-pm {
      display: inline-block;
      padding-left: 1rem;

      .checkbox {
        height: 22px;
        min-height: 22px;
        padding-top: 0px;
      }
    }
  }
}


/** REACT-AUTOSUGGEST **/
:global(.react-autosuggest__container) {
  position: relative;
  float: left;
}

:global(.react-autosuggest__input) {
  width: 240px;
  height: 35px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #AAA;
  border-radius: 0;
}

:global(.react-autosuggest__input:focus) {
  outline: none;
}

:global(.react-autosuggest__container--open .react-autosuggest__input) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

:global(.react-autosuggest__suggestions-container) {
  display: none;
}

:global(.react-autosuggest__container--open .react-autosuggest__suggestions-container) {
  display: block;
  position: absolute;
  top: 35px;
  width: 240px;
  border: 1px solid #AAA;
  background-color: #FFF;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

:global(.react-autosuggest__suggestions-list) {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

:global(.react-autosuggest__suggestion) {
  cursor: pointer;
  padding: 6px 20px;
}

:global(.react-autosuggest__suggestion--focused) {
  background-color: #DDD;
}

:global(.react-autosuggest__section-title) {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
  border-top: 1px dashed #CCC;
}

:global(.react-autosuggest__section-container:first-child .react-autosuggest__section-title) {
  border-top: 0;
}

/** END OF REACT-AUTOSUGGEST **/

/*
Modal
================================================================================
*/
:global{

  .modal-content {
    border-radius: 5px;
    @media (max-width: 1200px) {
      padding-bottom: 3rem;
    }

  }

  /*
  Modal Header
  ------------------------------------------------------------
  */
  .modal-header {
    padding: 3rem 3rem 2rem 3rem;
    border: none;

    /* TODO: Remove `!important` on `button` styles (above), then remove them here. */
    .close {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: 3rem;
      height: 3rem;
      margin: 0;
      padding: 0.3rem 1.1rem 0.7rem !important;
      border-radius: 100%;

      opacity: 1;
      background-color: $color-primary-lowlight;
      color: $color-white;

      font-size: 2rem;
      font-weight: normal;
      text-shadow: none;

      &:hover {
        font-weight: bold;
      }
    }

    .modal-title {
      float: left;

      color: $color-primary-highlight;

      font-size: 2.5rem;
      line-height: 1;

      @media (max-width: 767px) {
        float: none;
        text-align: center;
      }
    }
  }

  /*
  Modal Body
  ------------------------------------------------------------
  */
  .modal-body {
    padding: 0 3rem 3rem 3rem;
    border: none;
  }

  /*
  Modal Footer
  ------------------------------------------------------------
  */
  .modal-footer {
    padding: 0 3rem 3rem 3rem;
    border: none;
  }

  /*
  Modal Control
  ------------------------------------------------------------
  */
  .modal-controls {
    position: relative;
    float: right;

    @media (max-width: 767px) {
      float: none;
      text-align: center;
      margin-top: 1rem;
    }
  }


  .modal-control {
    border: 1px solid $color-primary-highlight;
    padding: 0.5rem 1rem;

    color: $color-white;
    background-color: $color-primary-highlight;

    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;

    &:hover {
      color: $color-primary-highlight;
      background-color: $color-white;
    }

    &:focus {
      outline: none;
    }

    &[disabled] {
      opacity: 0.5;
    }

    /* negate the hover effects when the button is disabled */
    &[disabled]:hover {
      color: $color-white;
      background-color: $color-primary-highlight;
    }
  }

} /* End Modal Styles */


/*
React Dropzone S3 Uploader Overrides
------------------------------------------------------------
NOTE: It applies styles on the element directly, thus the use of `!important`.
*/
:global {
  .react-dropzone-s3-uploader {
    border: none !important;
    border-radius: 0 !important;
  }
}
