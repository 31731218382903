:global {
  html,
  body {
    font-family: 'Barlow Semi Condensed', Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    color: #444 !important;
    /* max-width: 1800px; */

    #app,
    .modal-dialog {
      height: 100%;

      h1, .h1,
      h2, .h2
      {
        font-family: 'Rubik', 'Open Sans', Helvetica, Arial, sans-serif;
      }
    }
  }

  html {
  }

  body {
    height: 100%;
    background-color:rgba(189, 187, 187, .2);
  }
}
