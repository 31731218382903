/*
NavBar Component Styles
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
/* app */
@import '../../assets/styles/variables';


/*
NavBar
================================================================================
*/
.globalbanner {
  height: 50px;
  display: block;
  background: black;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 16px;
  line-height: 50px;
  text-align:center;
  color:white;
  font-weight:600;

    a {
      color: #fff;
      -webkit-font-smoothing: antialiased;

      &:hover, &:active, &:focus {
        color: #fff;
        text-decoration: none;
      }
    }

    .headline {
      text-transform: uppercase;
      font-size: 16px;
    }

    .cta {
      color: #f7fa07;
      text-transform: uppercase;
      margin-left: 1rem;
      &:hover {
        color: #f7fa07;
      }
    }

}
