/**
 * styles.module.css
 *
 * App container styles
 */
@import '../../assets/styles/variables';

:root {
  --nav-height: $nav-height;
}

.wrapper {
  height: 100%;
  margin-top: $nav-height;
}

.wrapper-dentist-new{
  overflow:hidden;
  height: 100%;
  max-width: 1800px;
}

.wrapper--no-navbar {
  height: 100%;
}

.container-wrapper {
  padding:0;
  margin-top: $nav-height;
  position: relative;
}

.content-wrapper {
  margin-top: 2rem;
  margin-bottom: 4rem;

}

.container-wrapper.dentist-new{
  /* min-height: 92vh; */
  /* background-color:rgba(189, 187, 187, .2); */
  margin-left: 215px;
  position: relative;
  padding-bottom: 3.4rem;
}

.container-wrapper.dentist-new.globalbanneron{
  /* min-height: 92vh; */
  /* background-color:rgba(189, 187, 187, .2); */
  margin-top:110px;
}

.container-wrapper.bclanding{
  /* min-height: 92vh; */
  /* background-color:rgba(189, 187, 187, .2); */
  margin-top: 0;
}

.container{
  padding:15px;
}

.memberlist-wrapper {
  padding: 20px;
}

.globalbanner {
  height: 30px;
  display: block;
  background: #00a1df;
  position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
}
