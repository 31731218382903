/* Roboto font definition */
@font-face {
  font-family: Roboto;
  src: url('../fonts/roboto/Roboto-Bold-webfont.eot');
  src:
    url('../fonts/roboto/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Bold-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Bold-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Bold-webfont.svg#robotobold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/roboto/Roboto-Light-webfont.eot');
  src:
    url('../fonts/roboto/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Light-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Light-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Light-webfont.svg#robotolight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/roboto/Roboto-Medium-webfont.eot');
  src:
    url('../fonts/roboto/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Medium-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Medium-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Medium-webfont.svg#robotomedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/roboto/Roboto-Regular-webfont.eot');
  src:
    url('../fonts/roboto/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Regular-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Regular-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Regular-webfont.svg#robotoregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/roboto/Roboto-Thin-webfont.eot');
  src:
    url('../fonts/roboto/Roboto-Thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Thin-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Thin-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Thin-webfont.svg#robotothin') format('svg');
  font-weight: 100;
  font-style: normal;
}

/* Open Sans font definition */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-ExtraBold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Epilogue';
  src: url('../fonts/Epilogue-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Epilogue';
  src: url('../fonts/Epilogue-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Epilogue';
  src: url('../fonts/Epilogue-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Epilogue';
  src: url('../fonts/Epilogue-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Termina';
  src: url('../fonts/Termina-Bold.otf');
  font-weight: 600;
  font-style: normal;
}